import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
    useEffect(() => {
        document.title = "Site Not Found - Ideas e.V. - wir leben Vielfalt"
    })
    return (
        <Link to={"/Home"}>
            <div style={{position:"absolute",left:"40%",top:"25%",transform:"translate(-50%,-50%),",border:"solid black 0.25vmin", borderRadius:"1vmin", padding:"0.25vh 0.5vw"}}>
                    <h2>404 - Not Found</h2>
                    <p>Sorry, die Seite die Sie erreichen möchten, existiert nicht.</p>
                    <p>Ideas e.V. - wir leben Vielfalt - Home</p>
            </div>
        </Link>
    );
};

export default NotFound;

