import '../../Data/Styles/Master.css';
import '../../Data/Styles/DataSpecific.css';
import '../../Data/Styles/Textblock.css';
import '../../Data/Styles/Veranstaltungorg.css';
import {replaceNewlinesWithBR} from "./StringTools";

export type VeranstaltungProps = {
    name: string
    datum: string
    ort: string
    kosten: string
    referent?: string
    hidden?: boolean
    moderation?: string
}

const Veranstaltung = (props: VeranstaltungProps) => {
    const {referent = ""} = props
    const {moderation = ""} = props
    const {hidden = false} = props
    return (
        <div hidden={hidden} className={referent !== "" ? "Veranstaltung" : "Veranstaltungnoref"}>
            <h1>
                {replaceNewlinesWithBR(props.name)}
            </h1>
            <table style={{marginTop: "2vh"}}>
                <tbody>
                    <tr>
                        <td className={"vdesc"}>
                            Datum:
                        </td>
                        <td>
                            {replaceNewlinesWithBR(props.datum)}
                        </td>
                    </tr>
                    <tr className={referent !== "" ? "" : "tr-disabled"}>
                        <td className={"vdesc"}>
                            Referent:
                        </td>
                        <td>
                            {replaceNewlinesWithBR(referent)}
                        </td>
                    </tr>
                    <tr className={moderation !== "" ? "" : "tr-disabled"}>
                        <td className={"vdesc"}>
                            Moderation:
                        </td>
                        <td>
                            {replaceNewlinesWithBR(moderation)}
                        </td>
                    </tr>
                    <tr>
                        <td className={"vdesc"}>
                            Ort:
                        </td>
                        <td>
                            {replaceNewlinesWithBR(props.ort)}
                        </td>
                    </tr>
                    <tr>
                        <td className={"vdesc"}>
                            Kosten:
                        </td>
                        <td>
                            {replaceNewlinesWithBR(props.kosten)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Veranstaltung;