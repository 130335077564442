export async function fetchDoc(path: string) {
    const response = await fetch(path);
    if(!response.ok) {
        throw new Error('Failed to fetch file');
    }
    return response;
}

export async function fetchDocJson(path: string) {
    const response = await fetchDoc(path);
    return await response.json();
}

export async function fetchDocText(path: string) {
    const response = await fetchDoc(path);
    return await response.text();
}