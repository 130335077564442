import React from 'react';
import {BrowserRouter, Link, Navigate, Route, Routes} from "react-router-dom";
import NotFound from "./Ideas/Tsx/General/NotFound";
import IdeasStruct from "./Ideas/Tsx/General/Structure";
import ImgSideContainer from "./Ideas/Tsx/General/ImgSideContainer";
import ImgContainer from "./Ideas/Tsx/General/ImgContainer";
import Veranstaltung from "./Ideas/Tsx/General/Veranstaltung";
import Veranstaltungen from "./Ideas/Tsx/General/Veranstaltungen";


function Root() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<Navigate to={"/Home"}/>}/>
                <Route path={"/Home"} element={<IdeasStruct site={"Home"} content={
                    <>
                        <h1 className="header">
                            Ideas e.V. - wir leben Vielfalt
                        </h1>
                        <p className="text">
                            Der Ideas e.V. soll neue Ideen passgenau etablieren.
                        </p>
                        <h2 className="subheader">
                            Was wir wollen
                        </h2>
                        <ImgSideContainer
                            src={"/Img/SiteSpecific/Home-1.jpg"}
                            imgClass={"borderedimg"}
                            alt={"Startbild"}
                            lsrc={"Pixabay.com"}
                            text={"Inklusion hat viele Aspekte – ob barrierefreie Zugänge zu Gebäuden und öffentlichen Plätzen, Mobilität für alle, barrierefreies Wohnen, Assistenz oder gleichberechtigte Teilhabe an Sport-, Kultur- und Freizeitangeboten. Wir möchten mit unserem Angebot einen Beitrag leisten und Menschen mit unsichtbaren Behinderungen eine Gleichberechtigung und Teilhabe zu ermöglichen."}
                        />
                        <h2 className="subheader">
                            Was uns motiviert
                        </h2>
                        <p className="text">
                            Integration und Inklusion passieren nicht von allein.
                        </p>
                        <ul className="content">
                            <li>
                                Menschen brauchen Raum zur Begegnung.
                            </li>
                            <li>
                                Sie sprechen verschiedene Sprachen.
                            </li>
                            <li>
                                Sie haben unterschiedliche Kulturen.
                            </li>
                            <li>
                                Sie haben unterschiedliche Geschichten.
                            </li>
                        </ul>
                        <h2 className="subheader">
                            Unsere Zielgruppe
                        </h2>
                        <ul className="content">
                            <li>
                                Menschen mit nicht sichtbaren Behinderungen zwischen 16 und 69, ausgenommen sind
                                Alkohol- und Drogensüchtige.
                            </li>
                            <li>
                                Mitarbeiter:innen der Einrichtungen
                            </li>
                            <li>
                                Angehörige
                            </li>
                            <li>
                                Ehrenamtliche
                            </li>
                            <li>
                                Bürger:innen
                            </li>
                            <li>
                                Sonstige Interessenten
                            </li>
                        </ul>
                        <h2 className="subheader">
                            Unsere Ziele
                        </h2>
                        <ul className="content">
                            <li>
                                Regelmäßige Veranstaltungen
                            </li>
                            <li>
                                Angebot, Koordination und Finanzierung von Hobbygruppe, Musik– oder Sportangeboten sowie
                                Reiten.
                            </li>
                            <li>
                                Inklusiver Therapiebauernhof
                            </li>
                            <li>
                                Den Betrieb von intensivbetreuten Wohngruppen für Patienten .
                            </li>
                            <li>
                                Unterstützung bei der Suche von Praktikumstellen, Ausbildungsplätzen oder Arbeitsplätzen
                                incl. Bewerbungstraining.
                            </li>
                            <li>
                                Durchführung von Vorträgen, Workshops und Coachings zum Thema „Gesundheit, Ernährung und
                                Sport“; „Kunst und Kreativität“, „MINT“ und „Berufliche Bildung“.
                            </li>
                        </ul>
                    </>
                }/>}/>
                <Route path={"/Projekte"} element={<IdeasStruct site={"Projekte"} mainClass={"RightImg"} content={
                    <>
                        <div className="flexcntblock textblock">
                            <h1>
                                Unsere Projektideen
                            </h1>
                            <ul>
                                <li>
                                    <h5>
                                        <Link to="/Projekte/Rosengarten">
                                            Rosengarten
                                        </Link>
                                    </h5>
                                    inklusiver Therapiebauernhof mit WG
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Projekte/Pool24">
                                            Pool24
                                        </Link>
                                    </h5>
                                    Kapazitäten frei? - z.B. Vermittlung von Alltagsbetreuern
                                </li>
                                <li>
                                    <h5>
                                      <span className="abutnota">
                                          Café Vielfalt
                                      </span>
                                    </h5>
                                    Spielenachmittag, Frühstück oder Café
                                </li>
                            </ul>
                        </div>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Projekte-1.png"}
                            alt={"Rose"}
                            lsrc={"C. Aufermann-Veith"}
                        />
                    </>
                }/>}/>
                <Route path={"/Projekte/Rosengarten"} element={<IdeasStruct site={"Rosengarten"} content={
                    <>
                        <ul className="content">
                            <li>
                                Auf einem Bauernhof ohne aktiven Landwirtschaftsbetrieb sollen diverse Therapien
                                angeboten werden.
                            </li>
                            <li>
                                Zum einen soll eine betreute gemischte Wohngemeinschaft für vier bis acht Personen mit
                                Autismus Spektrum Störung oder anderen psychosomatischen oder psychischen Diagnosen
                                eingerichtet werden.
                            </li>
                            <li>
                                Als zweites Angebot soll es Pferde und Kleintiere zur Therapieergänzung geben.
                            </li>
                            <li>
                                Dieses Angebot soll neben den Bewohnern von externen Klienten genutzt werden können.
                            </li>
                        </ul>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Rosengarten-1.jpg"}
                            alt={"Rosengarten"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Projekte/Pool24"} element={<IdeasStruct site={"Pool24"} content={
                    <>
                        <ul className="content">
                            <li>
                                Unser Pool24 soll eine Plattform sein, in der sich Betreute und Betreuende finden.
                            </li>
                            <li>
                                In diesem Angebot möchten wir langfristige Betreuungsangebote für Kinder und Jugendliche
                                sowie andere pflegebedürftige Familienmitglieder etablieren.
                            </li>
                            <li>
                                Und Angebote wie Computercoaching, Hausaufgabenbetreuung, Nachhilfe, Vorlesepaten,
                                Babysitten oder ähnliches kombinieren.
                            </li>
                        </ul>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Pool24-1.jpg"}
                            alt={"Pool24"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Projekte/Thementag"} element={<IdeasStruct site={"Thementag"} content={
                    <>
                        <ul className="content">
                            <li>
                                Es wird ein Tag zum Thema<br/>"Legt das Leben Dir Steine in den Weg, bau was Schönes
                                draus".
                            </li>
                            <li>
                                Hierzu gibt es Kreativ- und Entspannungsangebote.
                            </li>
                            <li>
                                Für Essen und Getränke wird gesorgt.
                            </li>
                        </ul>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Visionstag-1.jpg"}
                            alt={"Visionstag"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote"} element={<IdeasStruct mainClass={"RightImg"} site={"Angebote"} content={
                    <>
                        <div className="flexcntblock textblock">
                            <h1>
                                Unsere Angebote
                            </h1>
                            <ul>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Selbsthilfegruppe">
                                            Selbsthilfegruppe
                                        </Link>
                                    </h5>
                                    Wir möchten uns 14-tägig zum Thema psychosomatische Erkrankungen treffen. Wenn Sie
                                    betroffen sind, sind Sie herzlich bei uns willkommen.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Alltagsbegleiter">
                                            Alltagsbegleiter
                                        </Link>
                                    </h5>
                                    Sie helfen bei Verrichtungen des täglichen Lebens, assistieren im Alltag und sichern
                                    die Teilnahme am gesellschaftlichen Leben.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Hobbygruppe">
                                            Hobbygruppe
                                        </Link>
                                    </h5>
                                    Es gibt kreative Angebote wie Holzarbeiten, Korbflechten, Malen, Seidenmalerei,
                                    Schlüsselanhänger flechten und Steine bemalen.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Spielenachmittag">
                                            Spielenachmittag
                                        </Link>
                                    </h5>
                                    Sie haben die Möglichkeit, von uns mitgebrachte Spiele auszuprobieren, selbst
                                    Gesellschaftsspiele mitzubringen oder eigene Spiele (Autorenspiele) mit uns zu
                                    entwickeln.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Kreativnachmittag">
                                            Kreativnachmittag
                                        </Link>
                                    </h5>
                                    Wir bieten einen Kreativnachmittag mit Variationen in Holz, Steinen oder Papier an.
                                    Ihr könnt zwischen Laubsägearbeiten, Brandmalerei (auf Holz), Stone Art, Acryl
                                    Malerei und Mandalas wählen.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Vortrag">
                                            Vortrag
                                        </Link>
                                    </h5>
                                    Vortrag zu einem festzulegendem Thema.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Wochenendworkshops">
                                            Wochenendworkshops
                                        </Link>
                                    </h5>
                                    In Projektwochen(-enden) sollen Veranstaltungen geplant und durchgeführt sowie
                                    IT-Projekte geplant und realisiert werden.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Spielekoffer">
                                            Spielekoffer
                                        </Link>
                                    </h5>
                                    Gestalten Sie ihren individuellen Spielekoffer mit verschiedenen
                                    Gesellschaftsspielen und Gruppenspielen.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Nachhilfe">
                                            Nachhilfe
                                        </Link>
                                    </h5>
                                    Wir bieten Vermittlung für Nachhilfe an.
                                </li>
                                <li>
                                    <h5>
                                        <Link to="/Angebote/Bewerbungstraining">
                                            Bewerbungstraining
                                        </Link>
                                    </h5>
                                    In vier Stunden mit maximal zwei Teilnehmern erstellt Ihr Euren Lebenslauf.
                                </li>
                            </ul>
                        </div>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Projekte-1.png"}
                            alt={"Rose"}
                            lsrc={"C. Aufermann-Veith"}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote/Selbsthilfegruppe"} element={<IdeasStruct site={"Selbsthilfegruppe"} content={
                    <>
                        <p className="text">
                            Wir möchten uns 14-tägig zum Thema psychosomatische Erkrankungen treffen. Wenn Sie betroffen
                            sind, sind Sie herzlich bei uns willkommen.
                        </p>
                        <p className="text">
                            <Link to="/Kontakt" title="Kontakt - Ideas e.V. - wir leben Vielfalt"
                                  className="linebreakafter">Kontaktieren Sie uns per E-Mail oder telefonisch.</Link>
                        </p>
                    </>
                }/>}/>
                <Route path={"/Angebote/Alltagsbegleiter"} element={<IdeasStruct site={"Alltagsbegleiter"} content={
                    <>
                        <ul className="content">
                            <li>
                                Alltagsbegleiter/innen helfen bei Verrichtungen des täglichen Lebens, assistieren im
                                Alltag und sichern die Teilnahme am gesellschaftlichen Leben.
                            </li>
                            <li>
                                Sie wirken unterstützend und aktivierend, bieten z.B. persönlichkeits-, kreativitäts-
                                oder bewegungsfördernde Freizeit- und Gruppenaktivitäten an.
                            </li>
                        </ul>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Alltagsbegleiter-1.jpg"}
                            alt={"Alltagsbegleiter"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote/Hobbygruppe"} element={<IdeasStruct site={"Hobbygruppe"} content={
                    <>
                        <ul className="content">
                            <li>
                                Regelmäßig wird eine Hobbygruppe angeboten.
                            </li>
                            <li>
                                Es gibt kreative Angebote wie Holzarbeiten, Korbflechten, Malen, Seidenmalerei,
                                Schlüsselanhänger flechten und Steine bemalen.
                            </li>
                        </ul>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Hobbygruppe-1.jpg"}
                            alt={"Hobbygruppe"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote/Spielenachmittag"} element={<IdeasStruct site={"Spielenachmittag"} content={
                    <>
                        <p className="text">
                            Wir bieten einen Spielenachmittag an. Sie haben die Möglichkeit, von uns mitgebrachte Spiele
                            auszuprobieren, selbst Gesellschaftsspiele mitzubringen oder eigene Spiele (Autorenspiele)
                            mit uns zu entwickeln. Karteikarten, DIN A4 und DIN A3 Papier, Würfel und Spielfiguren sind
                            vorhanden.
                        </p>
                        <p className="text">
                            Das Angebot richtet sich an 16- bis 69-jährige mit und ohne Handicap, die Lust haben
                            gemeinsam Gesellschaftsspiele auszuprobieren.
                        </p>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Spielenachmittag-1.jpg"}
                            alt={"Spielenachmittag"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote/Kreativnachmittag"} element={<IdeasStruct site={"Kreativnachmittag"} content={
                    <>
                        <p className="text">
                            Wir bieten einen Kreativnachmittag mit Variationen in Holz, Steinen oder Papier an. Ihr
                            könnt zwischen Laubsägearbeiten, Brandmalerei auf Holz, Stone Art, Acryl Malerei und
                            Mandalas wählen.
                        </p>
                        <p className="text">
                            Das Angebot richtet sich an 16- bis 69-jährige mit und ohne Handicap, die Lust haben
                            gemeinsam kreativ zu werden.
                        </p>
                        <ImgContainer
                            src={"/Img/SiteSpecific/Kreativnachmittag-1.jpg"}
                            alt={"Kreativnachmittag"}
                            lsrc={"Pixabay.com"}
                            imgClass={"Img1 borderedimg"}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote/Vortrag"} element={<IdeasStruct site={"Vortrag"} content={
                    <>
                        <p className="text">
                            Vortrag zu einem festzulegendem Thema
                        </p>
                        <p className="text">
                            Das Angebot richtet sich an Betroffene, Angehörige und andere Interessierte.
                        </p>
                        <p className={"text"}>
                            Themenwünsche können gerne geäußert werden.
                        </p>
                    </>
                }/>}/>
                <Route path={"/Angebote/Wochenendworkshops"} element={<IdeasStruct site={"Wochenendworkshops"} content={
                    <>
                        <p className="text">
                            In Projektwochen(-enden) sollen Veranstaltungen geplant und durchgeführt sowie IT-Projekte
                            geplant und realisiert werden.
                        </p>
                    </>
                }/>}/>
                <Route path={"/Angebote/Wochenendworkshops/MSAccess_LibreBase"}
                       element={<IdeasStruct site={"MS Access / Libre Base"} content={
                           <>
                               <ImgSideContainer
                                   src={"/Img/SiteSpecific/MS_Access_Libre_Base-1.png"}
                                   alt={"MS Access / Libre Base"}
                                   lsrc={"C. Aufermann-Veith"}
                                   text={"Als Beispielprojekt soll eine Access-Datenbank zur Verwaltung von Büchern und Spielen für die Kinder-, Jugend- und Erwachsenenarbeit erstellt werden. Es soll ein Datenmodell, Eingabemasken und Listen erstellt werden."}
                                   imgClass={"borderedimg"}
                                   addClassInner={"distancerleft"}
                               />
                               <p className="text">
                                   Es soll ein Projektstrukturplan mit Phasen, Aufgaben und Meilensteinen definiert
                                   werden. Für die Aufgaben sollen Aufwandsschätzungen vorgenommen werden. Danach sollen
                                   Anordnungsbeziehungen (AOBs) definiert werden. Das geplante Projekt soll als
                                   Gantt-Diagramm und als Netzplan dargestellt werden. Dann sollen
                                   Was-Wäre-Wenn-Szenarien durchgespielt werden.
                               </p>
                           </>
                       }/>}/>
                <Route path={"/Angebote/Wochenendworkshops/LibreProject"}
                       element={<IdeasStruct site={"Libre Project"} content={
                           <>
                               <ImgSideContainer
                                   src={"/Img/SiteSpecific/Libre_Project-1.png"}
                                   imgClass={"borderedimg"}
                                   addClassInner={"distancerleft"}
                                   alt={"Libre Project"}
                                   lsrc={"C. Aufermann-Veith"}
                                   text={"Als Beispielprojekt soll ein \"Tag der offenen Tür\" durchgeführt werden. Der Termin und Inhalt der Veranstaltung und die Gästeliste müssen festgelegt werden. Außerdem muss das Projektteam aufgestellt werden."}
                               />
                               <p className="text">
                                   Es soll ein Projektstrukturplan mit Phasen, Aufgaben und Meilensteinen und einem
                                   Ressourcenpool definiert werden. Für die Aufgaben sollen Ressourcen eingeplant und
                                   Aufwandsschätzungen vorgenommen werden. Danach sollen Anordnungsbeziehungen (AOBs)
                                   definiert werden. Das geplante Projekt soll als Gantt-Diagramm (und als Netzplan)
                                   dargestellt werden. Dann sollen Ist-Daten erfasst werden und Was-Wäre-Wenn-Szenarien
                                   durchgespielt werden.
                               </p>
                           </>
                       }/>}/>
                <Route path={"/Angebote/Wochenendworkshops/Ausstellung"}
                       element={<IdeasStruct site={"Ausstellung"} content={
                           <>
                               <p className="text">
                                   Wir planen die Vorbereitung einer Ausstellung mit dem Thema „Was bedeutet Vielfalt für Sie?“.
                               </p>
                               <p className="text">
                                   An einem weiteren Termin soll die Ausstellung eröffnet werden. Ein Ausstellungsraum
                                   muss noch gefunden werden.
                                   Jeder Teilnehmer kann ein Objekt herstellen. Hierbei kann es sich um ein Bild, eine
                                   Kollage, ein Plakat oder eine andere Kreation handeln.
                               </p>
                               <p className="text">
                                   Als Materialien stehen Papier, Leinwand, Zeitschriften, Acryl-, Aquarellfarben,
                                   Holzbuntstifte und Ölpastellfarben zur Verfügung. Eigenes Material kann mitgebracht
                                   werden.
                               </p>
                               <p className="text">
                                   Das Angebot richtet sich an 16- bis 69-jährige mit und ohne Handicap, die Lust haben
                                   gemeinsam kreativ zu werden.
                               </p>
                               <ImgContainer
                                   src={"/Img/SiteSpecific/Ausstellung-1.jpg"}
                                   alt={"Ausstellung"}
                                   lsrc={"Pixabay.com"}
                                   imgClass={"Img1 borderedimg"}
                               />
                           </>
                       }/>}/>
                <Route path={"/Angebote/Spielekoffer"} element={<IdeasStruct site={"Spielekoffer"} content={
                    <>
                        <ImgSideContainer
                            imgClass={"borderedimg"}
                            addClassInner={"distancerleft"}
                            src={"/Img/SiteSpecific/Spielekoffer-1.png"}
                            alt={"Spielekoffer"}
                            lsrc={"C. Aufermann-Veith"}
                            text={"Sie sind in der Jugend-, Behinderten- oder Seniorenarbeit tätig? Als ein Beschäftigungsangebot haben Sie Gesellschaftsspiele oder Gruppenspiele im Portfolio? Ihren „Spiele- und Vorlese-Koffer“ verwalten Sie in einer Access-Datenbank?"}
                        />
                        <ImgSideContainer
                            imgClass={"borderedimg"}
                            addClassInner={"distancerright"}
                            imgRight={false}
                            src={"/Img/SiteSpecific/Spielekoffer-2.jpg"}
                            alt={"Spielekoffer"}
                            lsrc={"C. Aufermann-Veith"}
                            text={"Neben Klassikern wie Rummykub, Pachisi mit großen Figuren, Würfelspielen und Spielkarten haben Sie Gruppenspiele wie „Was bin ich“, „Die Montagsmaler“ und „Flash“ im Programm. Die Dokumente für Kniffel, Schiffe versenken und Stadt, Land, … haben Sie mit Ihrem Logo bedruckt."}
                        />
                    </>
                }/>}/>
                <Route path={"/Angebote/Nachhilfe"} element={<IdeasStruct site={"Nachhilfe"} content={
                    <>
                        <p className="text">
                            Wenn Sie Interesse an diesem Angebot haben, <Link to="/Kontakt"
                                                                              title="Kontakt - Ideas e.V. - wir leben Vielfalt"
                                                                              className="linebreakafter">kontaktieren
                            Sie uns per E-Mail oder telefonisch.</Link><br/>Vielen Dank.
                        </p>
                    </>
                }/>}/>
                <Route path={"/Angebote/Computercoaching"} element={<IdeasStruct site={"Computercoaching"} content={
                    <>
                        <p className={"text"}>
                            Wir bieten Unterstützung beim Einsatz des Computers für Bürotätigkeiten oder im Internet.
                        </p>
                        <p className="text">
                            Wenn Sie Interesse an diesem Angebot haben, <Link to="/Kontakt"
                                                                              title="Kontakt - Ideas e.V. - wir leben Vielfalt"
                                                                              className="linebreakafter">kontaktieren
                            Sie uns per E-Mail oder telefonisch.</Link><br/>Vielen Dank.
                        </p>
                    </>
                }/>}/>
                <Route path={"/Angebote/Bewerbungstraining"} element={<IdeasStruct site={"Bewerbungstraining"} content={
                    <>
                        <ImgSideContainer
                            imgClass={"borderedimg"}
                            addClassInner={"distancerleft"}
                            addClassOuter={"distancerup"}
                            center={false}
                            src={"/Img/SiteSpecific/Bewerbungstraining-1.jpg"}
                            alt={"Bewerbungstraining"}
                            lsrc={"Pixabay.com"}
                            text={"Wir bieten ein Bewerbungstraining für einen Praktikums- oder Ausbildungsplatz. In vier Stunden mit maximal zwei Teilnehmern erstellt Ihr Euren Lebenslauf. Wenn vorhanden, können eigene Laptops mitgebracht werden. Ihr erhaltet Tipps, wie offen Ihr mit Eurem „Handicap“ umgehen könnt."}
                        />
                        <p className="text">
                            Dieses Training wendet sich an 16 bis 27-jährige mit einer unsichtbaren Behinderung, die
                            sich auf dem 1.<span style={{visibility: "hidden"}}>l</span>Arbeitsmarkt
                            bewerben möchten.
                        </p>
                    </>
                }/>}/>
                <Route path={"/Veranstaltungen"} element={<IdeasStruct site={"Veranstaltungen"} content={<Veranstaltungen/>}/>}/>
                <Route path={"/Impressum"} element={<IdeasStruct site={"Impressum"} content={
                    <>
                        <h1 className="header">
                            Impressum
                        </h1>
                        <h2 className="subheader">
                            Verantwortlich:
                        </h2>
                        <p className="text">
                            Verantwortlich für den Inhalt gem. § 55 Abs. 2 RStV:
                        </p>
                        <p className="text">
                            Ideas e.V.
                            Christine Aufermann-Veith
                            Auf dem Gewann 15
                            52146 Würselen
                            Kontakt: ideas-aachen@web.de
                        </p>
                        <p className="text">
                            eingetragen im Vereinsregister beim Registergericht Aachen, VR 6326
                        </p>
                        <p className="text">
                            Die rechtlichen Grundlagen des Datenschutzes finden sich im Bundesdatenschutzgesetz (BDSG)
                            und dem Telemediengesetz (TMG).
                        </p>
                        <h2 className="subheader">
                            Haftung für Inhalte
                        </h2>
                        <p className="text">
                            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
                            den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                            jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                            oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                            allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                            ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                            von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                        </p>
                        <h2 className="subheader">
                            Haftung für Links
                        </h2>
                        <p className="text">
                            Unser Angebot kann Links zu externen Websites Dritter enthalten, auf deren Inhalte wir
                            keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                            übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                            Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
                            der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
                            ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                            Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                        </p>
                        <h2 className="subheader">
                            Urheberrecht
                        </h2>
                        <p className="text">
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                            deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
                            des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
                            privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
                            vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                            werden Inhalte Dritter als solche gekennzeichnet.
                        </p>
                        <p className="text">
                            Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                            Inhalte umgehend entfernen.
                        </p>
                    </>
                }/>}/>
                <Route path={"/Kontakt"} element={<IdeasStruct site={"Kontakt"} content={
                    <>
                        <ul className="contact">
                            <li>
                                Ideas e.V. - Wir leben Vielfalt
                            </li>
                            <li>
                                Auf dem Gewann 15
                            </li>
                            <li>
                                52146 Würselen
                            </li>
                            <li>
                                +49 2406 9251985
                            </li>
                            <li>
                                <a href="mailto:Ideas-aachen@web.de">
                                    Ideas-aachen@web.de
                                </a>
                            </li>
                        </ul>
                    </>
                }/>}/>
                <Route path={"/Datenschutz"} element={<IdeasStruct site={"Datenschutz"} content={
                    <>
                        <iframe title={"Datenschutz"} src="/Data/Datenschutz.pdf#toolbar=0" width="90%" height="500vh" className="DataDisplay"
                                seamless loading="eager"></iframe>
                    </>
                }/>}/>
                <Route path={"/Satzung"} element={<IdeasStruct site={"Satzung"} content={
                    <>
                        <iframe title={"Satzung"} src="/Data/Satzung.pdf#toolbar=0" width="90%" height="500vh" className="DataDisplay"
                                seamless loading="eager"></iframe>
                    </>
                }/>}/>
                <Route path={"/*"} Component={NotFound}/>
            </Routes>
        </BrowserRouter>
    );
}


export default Root;
