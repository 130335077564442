import '../../Data/Styles/Master.css';
import '../../Data/Styles/DataSpecific.css';
import '../../Data/Styles/Textblock.css';
import '../../Data/Styles/Veranstaltungorg.css';

type ImgCProps = {
    src: string
    alt: string
    lsrc: string
    addClass?: string
    imgClass?: string
}

const ImgContainer = (props: ImgCProps) => {
    const {addClass = ""} = props
    const {imgClass = ""} = props
    return (
        <div className={"ImgContainer " + addClass}>
            <img src={props.src} alt={props.alt} className={imgClass}/>
                <p>
                    Bild : {props.lsrc}
                </p>
        </div>)
}

export default ImgContainer;
