import '../../Data/Styles/Master.css';
import '../../Data/Styles/DataSpecific.css';
import '../../Data/Styles/Textblock.css';
import '../../Data/Styles/Veranstaltungorg.css';
import {Link, NavLink} from "react-router-dom";
import {useEffect} from "react";

type IdSProps = {
    site: String
    content: JSX.Element
    mainClass?: string
}
const IdeasStruct = (props: IdSProps) => {
    const {mainClass = ""} = props

    useEffect(() => {
        document.title = props.site + " - Ideas e.V. - wir leben Vielfalt"
    })

    let NewsContent: JSX.Element | null = null

    return (
        <>
            <header>
                <Link to={"/Home"} title={"Home - Ideas e.V. - wir leben Vielfalt"}>
                    <img src="/Img/Logo.png" alt="Logo"/>
                </Link>
                <span className="current">
                    {props.site}
                </span>
                <span className="slogan">
                     Ideas e.V. - wir leben Vielfalt
                </span>
            </header>
            <section className={"mid"}>
                <section className={"left"}>
                    <nav>
                        <ul className={"navmain"}>
                            <li>
                                <NavLink to={"/Home"} className={({isActive}) => isActive ? "layer1 current" : "layer1"}>
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/Projekte"} className={({isActive}) => isActive ? "layer1 current" : "layer1"}>
                                    Projekte
                                </NavLink>
                                <ul className={"layer1"}>
                                    <li>
                                        <NavLink to={"/Projekte/Rosengarten"} className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Rosengarten
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Projekte/Pool24"} className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Pool24
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Projekte/Thementag"} className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Thementag
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={"/Angebote"} className={({isActive}) => isActive ? "layer1 current" : "layer1"}>
                                    Angebote
                                </NavLink>
                                <ul className={"layer1"}>
                                    <li>
                                        <NavLink to={"/Angebote/Selbsthilfegruppe"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Selbsthilfegruppe
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Alltagsbegleiter"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Alltagsbegleiter
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Hobbygruppe"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Hobbygruppe
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Spielenachmittag"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Spielenachmittag
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Kreativnachmittag"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Kreativnachmittag
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Vortrag"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Vortrag
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Wochenendworkshops"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Wochenendworkshops
                                        </NavLink>
                                        <ul className={"layer2"}>
                                            <li>
                                                <NavLink to={"/Angebote/Wochenendworkshops/MSAccess_LibreBase"}
                                                         className={({isActive}) => isActive ? "layer3 current" : "layer3"}>
                                                    MS Access / Libre Base
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/Angebote/Wochenendworkshops/LibreProject"}
                                                         className={({isActive}) => isActive ? "layer3 current" : "layer3"}>
                                                    Libre Project
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/Angebote/Wochenendworkshops/Ausstellung"}
                                                         className={({isActive}) => isActive ? "layer3 current" : "layer3"}>
                                                    Ausstellung<br/>"Was bedeutet Vielfalt für Sie?"
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Spielekoffer"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Spielekoffer
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Nachhilfe"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Nachhilfe
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Computercoaching"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Computercoaching
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/Angebote/Bewerbungstraining"}
                                                 className={({isActive}) => isActive ? "layer2 current" : "layer2"}>
                                            Bewerbungstraining
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={"/Veranstaltungen"}
                                         className={({isActive}) => isActive ? "layer1 current" : "layer1"}>
                                    Veranstaltungen
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <section className={"News " + (NewsContent==null ? "unused" : "")}>
                        <h2>
                            News
                        </h2>
                        {NewsContent}
                    </section>
                </section>
                <section className={"right"}>
                    <section className={"nocollide"}>
                        <main className={mainClass}>
                            {props.content}
                        </main>
                    </section>
                </section>
            </section>
            <footer>
                <div className={"footerlink"}>
                    <div className={"format"}>
                        <NavLink to="/Impressum" className={({isActive}) => isActive ? "current" : ""}>
                            Impressum
                        </NavLink>
                    </div>
                </div>
                <div className={"footerlink"}>
                    <div className={"format"}>
                        <NavLink to={"/Kontakt"} className={({isActive}) => isActive ? "current" : ""}>
                            Kontakt
                        </NavLink>
                    </div>
                </div>
                <div className={"footerlink"}>
                    <div className={"format"}>
                        <NavLink to={"/Datenschutz"} className={({isActive}) => isActive ? "current" : ""}>
                            Datenschutz
                        </NavLink>
                    </div>
                </div>
                <div className={"footerlink"}>
                    <div className={"format"}>
                        <NavLink to="/Satzung" className={({isActive}) => isActive ? "current" : ""}>
                            Satzung
                        </NavLink>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default IdeasStruct;