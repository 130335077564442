import '../../Data/Styles/Master.css';
import '../../Data/Styles/DataSpecific.css';
import '../../Data/Styles/Textblock.css';
import '../../Data/Styles/Veranstaltungorg.css';
import ImgContainer from "./ImgContainer";

type ImgSCProps = {
    src: string
    alt: string
    lsrc: string
    addClassInner?: string
    text: string
    addClassOuter?: string
    imgClass?: string
    center?: boolean
    imgRight?: boolean
}

const ImgSideContainer = (props: ImgSCProps) => {
    const {imgClass = ""} = props
    const {addClassInner = "" } = props
    const {addClassOuter = "" } = props
    const {center = true } = props
    const {imgRight = true} = props

    return (
        <div className={(center ? "imgsidecontainer " : "imgsidecontainernocenter ") + addClassOuter}>
            {imgRight ? <p className="text">
                {props.text}
            </p> : <></>}
            <ImgContainer src={props.src} alt={props.alt} lsrc={props.lsrc} addClass={addClassInner} imgClass={imgClass}/>
            {!imgRight ? <p className="text">
                {props.text}
            </p> : <></>}
        </div>)
}

export default ImgSideContainer;