import '../../Data/Styles/Master.css';
import '../../Data/Styles/DataSpecific.css';
import '../../Data/Styles/Textblock.css';
import '../../Data/Styles/Veranstaltungorg.css';
import Veranstaltung, {VeranstaltungProps} from "./Veranstaltung";
import React, {useEffect, useState} from "react";
import {fetchDocJson, fetchDocText} from "../../Ts/Filemanage";

const Veranstaltungen = () => {
    const [data, setData] = useState<VeranstaltungProps[] | undefined>(undefined);
    useEffect(() => {
        fetchDocText("/Data/Veranstaltungen.json").then((text) => {console.log(text)});
        fetchDocJson("/Data/Veranstaltungen.json").then((json) => {setData(json)});
    }, []);
    return (
        <>
            {data===undefined ? "" : data.map((value, index) => (
                <Veranstaltung
                    key={index}
                    name={value.name}
                    datum={value.datum}
                    ort={value.ort}
                    kosten={value.kosten}
                    referent={value.referent}
                    moderation={value.moderation}
                    hidden={value.hidden}
                />
            ))}
        </>
    )
}

export default Veranstaltungen;